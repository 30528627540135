import React from 'react';
import { graphql } from 'gatsby';

import AuthorCard from '../components/AuthorCard';
import Banner from '../components/Banner';
import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import Layout from '../components/layout';
import SEO from '../components/seo';
import { getCarlAuthors, getExternalAuthors } from '../utils/authorSortUtils';

const Authors = ({ data }) => {
  const { header, authors } = data.gatsby;
  var carlAuthors = getCarlAuthors(authors);
  var externalAuthors = getExternalAuthors(authors);
  const thumbnails = {
    thumbnailSmall: (header.thumbnailSmall.length ? header.thumbnailSmall[0].url : null),
    thumbnailMedium: (header.thumbnailMedium.length ? header.thumbnailMedium[0].url : null),
    thumbnailLarge: (header.thumbnailLarge.length ? header.thumbnailLarge[0].url : null)
  }

  return (
    <Layout>
      <SEO seomatic={header.seomatic} />
      <ContentHeaderBgImg
        title={header.headline}
        text={header.description}
        meta={header.excerpt}
        thumbnails={thumbnails}
      />
      <section className="my-10 md:my-16">
        <div className="grid grid-cols-1 mx-auto max-w-screen-xxl">
          <div className="px-5 md:px-10">
            <h3 className="text-17 mb-3 md:text-xl md:mb-8 xl:text-3xl xl:mb-12">
              Unsere Nachfolge-Experten
            </h3>
            <div className="grid md:grid-cols-2 sm:gap-4 md:row-gap-8 md:mb-8 xl:grid-cols-3 xl:row-gap-16 xl:mb-10">
              {carlAuthors.map(author => (
                <AuthorCard author={author} key={author?.id} />
              ))}
            </div>
          </div>
        </div>
        {header.selectedBanner.length ? 
          <Banner banner={header.selectedBanner} />
          : null}
        {externalAuthors.length > 0 ? (
          <div className="grid grid-cols-1 mx-auto max-w-screen-xxl">
            <div className="px-5 md:px-10">
              <h3 className="text-17 mb-3 md:text-xl md:mb-8 xl:text-3xl xl:mt-3 xl:mb-12">
                Gastautoren & Partner
              </h3>
              <div className="grid md:grid-cols-2 sm:gap-4 sm:row-gap-8 xl:grid-cols-3 xl:row-gap-16 mb-2 md:mb-5 xl:mb-8">
                {externalAuthors.map(author => (
                  <AuthorCard author={author} key={author?.id} />
                ))}
              </div>
            </div>
          </div>
          ) : (
            null
          )
        } 
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    gatsby {
      header: entry(slug: "ueber-uns") {
        ... on Gatsby_about_about_Entry {
          headline
          description
          excerpt
          seomatic {
            metaTitleContainer
            metaTagContainer
          }
          thumbnailLarge {
            url
          }
          thumbnailMedium {
            url
          }
          thumbnailSmall {
            url
          }
          selectedBanner {
            ... on Gatsby_banner_banner_Entry {
              image {
                url
              }
              bannerHeadline {
                ... on Gatsby_bannerHeadline_headlineArea_BlockType {
                  headline
                  subHeadline
                }
              }
              bannerContent {
                ... on Gatsby_bannerContent_bannerContentSelection_BlockType {
                  description
                  citation {
                    ... on Gatsby_citation_citationContent_BlockType {
                      quoteAuthor
                      quoteAuthorPosition
                      quoteText
                    }
                  }
                  list {
                    ... on Gatsby_list_item_BlockType {
                      id
                      text
                    }
                  }
                }
              }
              bannerFooter {
                ... on Gatsby_bannerFooter_footerContent_BlockType {
                  showEmailForm
                  uniformResourceLocator
                  button {
                    ... on Gatsby_button_buttonSettings_BlockType {
                      buttonText
                      buttonUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      authors: entries(section: "articleAuthor") {
        ... on Gatsby_articleAuthor_articleAuthor_Entry {
          id
          avatar {
            url
          }
          description
          position
          carlEmployee
          email
          nameField
          phone
          uri
        }
      }
    }
  }
`;

export default Authors;
