export const getCarlAuthors = (authors) => {
  const result = []

  for (var author of authors) {
    if(author?.carlEmployee) {
      result.splice(0, 0, author);
    }
  }

  return result
}

export const getExternalAuthors = (authors) => {
  const result = []

  for (var author of authors) {
    if(author?.carlEmployee === false) {
      // result.push(author)
      result.splice(0, 0, author);
    }
  }
  
  return result
}