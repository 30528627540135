import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

const TertiaryLink = props => {
  const { className, text, white, url } = props
  return (
    <a
      href={url ? `/${url}` : null}
      {...props}
      className={classNames(
        {
          'text-15 cursor-pointer flex items-start focus:outline-none focus:shadow-none transition-all duration-200 ease-in-out': true,
          'text-blue-900 hover:text-blue-500 active:text-blue-200 focus:text-blue-200': !white,
          'text-white': white
        },
        className
      )}
    >
      <span className="mr-1 uppercase inline-block">{text}</span>
      <IosArrowForward color="currentColor" />
    </a>
  )
}

TertiaryLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired
}

export default TertiaryLink
