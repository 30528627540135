import React from 'react'
import PropTypes from 'prop-types'

import TertiaryLink from '../components/UIComponents/TertiaryLink'

const AuthorCard = ({ author, isExpert }) => {
  return (
    <div className="max-w-screen-xxl mx-auto grid grid-cols-40-60 gap-2 my-3 mb-4 md:gap-3 lg:grid-cols-2">
      <div className="mr-2">
        <img src={author?.avatar[0].url} alt={author?.nameField} loading="lazy" />
      </div>
      <div className="break-words">
        <p className="text-base mb-3 md:text-xl xl:text-23 xl:mb-4">
          {author?.nameField}
        </p>
        <p className="text-13 mb-4 md:text-15 md:mb-6 xl:text-base">
          {author?.position}
        </p>
        <div className="text-13 mb-4 md:mb-6">
          <p>
            <a href={`tel:${author?.phone}`} className="link">
              {author?.phone}
            </a>
          </p>
          <p>
            <a href={`mailto:${author?.email}`} className="link">
              {author?.email}
            </a>
          </p>
        </div>
        <div className="text-15 mb-2 md:mb-4 xl:text-base">
          {author?.description}
        </div>
        <TertiaryLink text={isExpert ? "Mehr Zum Experten" : "Mehr Zum Autor"} url={author?.uri} />
      </div>
    </div>
  )
}

AuthorCard.propTypes = {
  author: PropTypes.shape({
    carlEmployee: PropTypes.bool,
    description: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    avatar: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    )
  }),
  isExpert: PropTypes.bool,
}

export default AuthorCard
